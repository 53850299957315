<template>
  <div>
    <!--    <commingSoon />-->
    <b-container>
      <b-card class="card-about">
        <h2
          class="text-center text-primary font-weight-bold position-relative main-header mb-5"
        >
          {{ $t("auth.aboutUs") }}
        </h2>
        <p class="text-center font-size-20 text-black">
          {{ aboutUs }}
        </p>
      </b-card>
    </b-container>
  </div>
</template>
<script>
export default {
  name: "aboutUs",
  computed: {
    aboutUs() {
      return this.$store.getters["Setting/appSettings"]["aboutus"];
    },
  },
};
</script>
<style lang="scss">
.card-about {
  margin-top: 3rem;
  padding: 3rem;
  text-align: center;
}
</style>
